import React, { useEffect } from 'react'
import './App.css';
import 'react-toastify/dist/ReactToastify.css';
import Login from './screens/login'
import Forgot from './screens/forgotpassword';
import ResetPassword from './screens/resetpassword/web/ResetPassword';
// import Sidebar from './Sidebar';
import OnBoard from './screens/onboard/index';
import OnboardEmployee from './screens/onboarded/index';
import Notification from './screens/notifications/';

import ViewLead from './screens/lead/';
import ViewDeal from './screens/deal/';
import ViewContact from './screens/contacts/';
import ViewTasks from './screens/tasks/';
import ViewMettings from './screens/mettings/';
import Analytics from './screens/Analytics/Analytics';

import BdDashboard from './screens/dashboard/bd_dashboard';
import ControllerDashboard from './screens/dashboard/controller_dashboard';
import ItDashboard from './screens/dashboard/It_dashboard/web/It_dashboard';

import CreateLead from './screens/lead/index1'
import CreateDeal from './screens/deal/index1'
import CreateContact from './screens/contacts/index1'
import CreateTasks from './screens/tasks/index1'

import LeadDetail from './screens/lead/index2';
import DealDetail from './screens/deal/index2';
import ContactDetail from './screens/contacts/index2';
import TaskDetail from './screens/tasks/index2';
import MettingDetail from './screens/mettings/index1';

import TrackLead from './screens/lead/web/TrackLead'; 


import { useLocation } from "react-router";

import {
  BrowserRouter as Router,
  Routes,
  Route,
} from "react-router-dom";



import Navbar from './screens/navbar/';
import Profile from './screens/profile/';
import MoreOption from './screens/more/MoreOption';

import axios from 'axios';
import EditProfile from './screens/profile/index1';

import AddDdr from './screens/AddingControllerComponents/index'

import HomeMain from './screens/home/HomeMain';

import TeamCLD from './screens/bdsuperadmin/index';



//protectedRoutes
import RequireAuth from './protectedRoutes/RequireAuth'
import PageNotFound from './screens/pagenotfound/web/PageNotFound';
import PageNotFound1 from './screens/pagenotfound/web/PageNotFound1';
import LinkExpire from './screens/pagenotfound/web/LinkExpire'
// import KrishnaDashboard from './screens/dashboard/KrishnaDashboard';
import UpdatePassword from './screens/updatepassword/web/UpdatePassword';


import CLDmainDesign from './components/detail_design/CLDmainDesign'
import AddIndustry from './screens/KrishnaDashboardPages/index1';
import AddContactSource from './screens/KrishnaDashboardPages/index2';
import DeveloperDashboard from './screens/KrishnaDashboardPages/DeveloperDashboard';

import { message } from 'antd'
import Notesui from './components/bdcomponenttable/mobileComponents/Notesui';
import Attachmentsui from './components/bdcomponenttable/mobileComponents/Attachmentsui';

// import { messaging } from './firebase'
// import { getMessaging, getToken, onMessage } from "firebase/messaging";

// import { useDispatch } from 'react-redux';
// import { device_token } from './redux/actionPayload/AuthPayload';
import Reportgeneration from './screens/KrishnaDashboardPages/web/Reportgeneration';
import Settings from './screens/KrishnaDashboardPages/web/Settings';
import Processor from './screens/ItDashboardPages/Processor';
import Brand from './screens/ItDashboardPages/Brand';
import Wire from './screens/ItDashboardPages/Wire';
import Connector from './screens/ItDashboardPages/Connector';
import UserXLContact from './screens/KrishnaDashboardPages/web/UserXlContact';

import FmsDashboardMain from './screens/fmsDashboardPages/web/FmsDashboardMain';
import EmployeeInformationForm from './screens/fmsDashboardPages/web/EmployeeInformationForm';
import StatementForm from './screens/fmsDashboardPages/web/StatementForm';
import FormF from './screens/fmsDashboardPages/web/FormF';
import FmsClients from './screens/fmsDashboardPages/web/FmsClients';
import FmsEmployee from './screens/fmsDashboardPages/web/FmsEmployee';
import FmsDesignationOnboardEmployee from './screens/fmsDashboardPages/web/FmsDesignationOnboardEmployee';
import UpdateUserPassword from './screens/KrishnaDashboardPages/web/UpdateUserPassword';
import FmsEmployeeDetail from './screens/fmsDashboardPages/web/FmsEmployeeDetail';

import Mouse from './screens/ItDashboardPages/Mouse';
import DeviceStage from './screens/ItDashboardPages/DeviceStage';
import Keyboard from './screens/ItDashboardPages/Keyboard';
import Disk from './screens/ItDashboardPages/Disk';
import Laptop from './screens/ItDashboardPages/Laptop';
import AssetsAssign from './screens/ItDashboardPages/AssetsAssign';
import PurchaseType from './screens/ItDashboardPages/PurchaseType';
import Mobile from './screens/ItDashboardPages/Mobile';
import Desktop from './screens/ItDashboardPages/Desktop';
import Cpu from './screens/ItDashboardPages/Cpu';
import FmsExitEmployeelist from './screens/fmsDashboardPages/web/FmsExitEmployeelist';
import FmsExitFormCreate from './screens/fmsDashboardPages/web/FmsExitFormCreate';
import FmsClientScan from './screens/fmsDashboardPages/web/FmsClientScan';
import FmsExitFormCreate1 from './screens/fmsDashboardPages/web/FmsExitFormCreate1';
import ExitEmployeeList from './screens/fmsDashboardPages/web/ExitEmployeeList';
import Tickets from './screens/fmsDashboardPages/web/Tickets';
import TicketsCE from './screens/fmsDashboardPages/web/TicketsCE';
import FmsClientsPoints from './screens/fmsDashboardPages/web/FmsClientsPoints';
import AssignClients from './screens/fmsDashboardPages/web/AssignClients';
import AssignClientsCE from './screens/fmsDashboardPages/web/AssignClientsCE';
import HandlingClient from './screens/fmsDashboardPages/web/HandlingClient';
import EmployeeBasedOnClient from './screens/fmsDashboardPages/web/EmployeeBasedOnClient';
import AttendanceOptionsRoot from './screens/fmsDashboardPages/web/AttendanceOptionsRoot';
import AttendanceOptionsCE from './screens/fmsDashboardPages/web/AttendanceOptionsCE';
import AddAttendanceForSelectedEmployee from './screens/fmsDashboardPages/web/AddAttendanceForSelectedEmployee';
import DownloadAttendence from './screens/fmsDashboardPages/web/DownloadAttendence';
import AttendanceOptionBaseList from './screens/fmsDashboardPages/web/AttendanceOptionBaseList';
import UploadExcel from './screens/fmsDashboardPages/web/UploadExcel';
import FmsEmployeeBasedClient from './screens/fmsDashboardPages/web/FmsEmployeeBasedClient';
import FmsHrPolicy from './screens/fmsDashboardPages/web/FmsHrPolicy';
import FmsDispensaryOnboardEmployee from './screens/fmsDashboardPages/web/FmsOnboardedEmployeeDispensary';
import ScanCheckList from './screens/fmsDashboardPages/web/ScanCheckList';
import FmsRoles from './screens/fmsDashboardPages/web/FmsRoles';
import FmsInHouseEmployee from './screens/fmsDashboardPages/web/FmsInHouseEmployee';
import FmsInHouseEmployeeCE from './screens/fmsDashboardPages/web/FmsInHouseEmployeeCE';
import FmsClientsSalaryStatement from './screens/fmsDashboardPages/web/FmsClientsSalaryStatement';
import FmsEmployeeAssignList from './screens/fmsDashboardPages/web/FmsEmployeeAssignList';
import ClientInvoice from './screens/fmsDashboardPages/web/ClientInvoice';
import ClientInvoiceCE from './screens/fmsDashboardPages/web/ClientInvoiceCE';
import MissedClientScan from './screens/fmsDashboardPages/web/MissedClientScan';
import FmsClientsSalaryStatementCE from './screens/fmsDashboardPages/web/FmsClientsSalaryStatementCE';
import JobCardList from './screens/fmsDashboardPages/web/job_card/JobCardList';
import JobCardCE from './screens/fmsDashboardPages/web/job_card/JobCardCE';
import WorkAssignedList from './screens/fmsDashboardPages/web/team_work/WorkAssignedList';
import WorkAssignedCE from './screens/fmsDashboardPages/web/team_work/WorkAssignedCE';
import WorkAssignedListDetail from './screens/fmsDashboardPages/web/team_work/WorkAssignedListDetail';
import DownloadClientAttendance from './screens/fmsDashboardPages/web/DownloadClientAttendance';
import FmsEmployeeOverallList from './screens/fmsDashboardPages/web/FmsEmployeeOverallList';
import FmsLongLeaveCreate from './screens/fmsDashboardPages/web/FmsLongLeaveFormCreate';
import FmsLongLeaveList from './screens/fmsDashboardPages/web/FmsLongLeaveFormList';

// import firebase from './firebase';
// import MailNavbar from './screens/mail/web/MailNavbar';

//server url 
axios.defaults.baseURL = 'https://fcplmain.com/api/'
// axios.defaults.baseURL = 'http://192.168.9.99:4001/api/'

// test url 
// axios.defaults.baseURL = 'http://ec2-65-0-21-88.ap-south-1.compute.amazonaws.com:5000/api/'

//local url   
// axios.defaults.baseURL = 'http://192.168.8.14:4001/api/'

//server url update test 1
// export const base_url = 'https://fcplmain.com/'

// test url 
// export const base_url = 'http://ec2-65-0-21-88.ap-south-1.compute.amazonaws.com:5000/'

//local url 
export const base_url = 'https://fcplfmsbucket.s3.ap-south-1.amazonaws.com/'
export const local_base_url = 'https://fcplmain.com/'
// export const local_base_url = 'http://192.168.9.99:4001/'

message.config({
  maxCount: 1,  
})

const Root = () => {
  const location = useLocation()


  const { pathname } = location


  // const navigate = useNavigate()

  // useEffect(()=>{
  //    if(pathname === '/api/user/verify-reset-token'){
  //     //  navigate('/reset-password?')
  //    }
  // },[pathname])



  

  return (
    // <>

    // </>
    <div>

      {window.innerWidth > 768 && 

      //dashboards
      location.pathname !== '/krishna_dashboard' &&
      location.pathname !== '/it_dashboard' &&
      //auth
      location.pathname !== '/login' &&
      location.pathname !== '/forgot_password' &&
      location.pathname !== '/update-password' &&
      location.pathname !== '/reset_password' &&


      location.pathname !== '/' &&
      location.pathname !== '/notifications' &&
      location.pathname !== '/profile' &&
      location.pathname !== '/more-options' &&
     
      location.pathname !== '/add_industry' &&
      location.pathname !== '/contact_source' &&
      location.pathname !== '/crm_report'  &&
      location.pathname !== '/settings' && 
      location.pathname.split('/')[1] !== 'it' &&
      location.pathname.split('/')[1] !== 'rk' &&
      location.pathname.split('/')[1] !== 'fms' &&
        <Navbar />}
      
      {window.innerWidth < 768 &&
        location.pathname !== '/login' &&
        location.pathname !== '/forgot_password' &&
        location.pathname !== '/reset-password?token=:token' &&
        location.pathname !== '/update-password' &&
        location.pathname !== '/' &&
        location.pathname !== '/notifications' &&
        location.pathname !== '/profile' &&
        location.pathname !== '/more-options' &&
        location.pathname !== '/create_contact' &&
        location.pathname !== '/create_deal' &&
        location.pathname !== '/create_lead' &&
        location.pathname !== '/create_tasks' &&
        location.pathname !== '/lead_detail' &&
        location.pathname !== '/deal_detail' &&
        pathname.split('/')[1] !== 'contact_detail' &&
        pathname.split('/')[1] !== 'lead_detail' &&
        pathname.split('/')[1] !== 'deal_detail' &&
        pathname.split('/')[1] !== 'edit_task' &&
        pathname.split('/')[1] !== 'edit_contact' &&
        pathname.split('/')[1] !== 'edit_lead' &&
        pathname.split('/')[1] !== 'edit_deal' &&
        pathname.split('/')[1] !== 'notes_ui' &&
        pathname.split('/')[1] !== 'attachments_ui' &&
        pathname !== '/on_board' &&
        pathname !== '/onboarded_employee' &&
        pathname !== '/TeamCLD' &&
        pathname.split('/')[1] !== 'on_board_user_update' &&

        location.pathname !== '/view_meetings' &&
        location.pathname !== '/view_tasks' &&
        location.pathname !== '/view_contacts' &&
        location.pathname !== '/view_deals' &&
        location.pathname !== '/view_leads' &&
        location.pathname !== '/krishna_dashboard' &&
        location.pathname !== '/it_dashboard' &&
        location.pathname !== '/add_industry' &&
        location.pathname !== '/contact_source' &&
        location.pathname !== '/crm_report' && <Navbar />}

      <Routes>
        <Route path="/" element={<HomeMain />} />
        <Route path="/login" element={<Login />} />
        <Route path="/forgot_password" exact element={<Forgot />} />
        <Route path="/reset_password" element={<ResetPassword />} />
        <Route path="/main_ra" exact element={<CLDmainDesign />} />


        <Route element={<RequireAuth allowedRoles={['admin', 'hod', 'manager', 'db_head', 'db_user', 'db_sub_user', 'bd_user', 'controller','fms_user','fms_hr']} />}>
          <Route path="/update-password" element={<UpdatePassword />} />
          <Route path="/notifications" exact element={<Notification />} />
          <Route path="/profile" exact element={<Profile />} />
          <Route path="/edit-profile" exact element={<EditProfile />} />
        </Route>


        {/* <Route element={<RequireAuth allowedRoles={['admin', 'hod', 'manager', 'db_head', 'db_user', 'db_sub_user', 'bd_user','fms_user','fms_hr']} />}>
          <Route path="/bd_dashboard" exact element={<BdDashboard />} />

          <Route path="/view_leads" exact element={<ViewLead />} />
          <Route path="/view_deals" exact element={<ViewDeal />} />
          <Route path="/view_contacts" exact element={<ViewContact />} />
          <Route path="/view_tasks" exact element={<ViewTasks />} />
          <Route path="/view_meetings" exact element={<ViewMettings />} />
          <Route path="/view_analytics" exact element={<Analytics />} />

          <Route path="/create_lead" exact element={<CreateLead />} />
          <Route path="/create_deal" exact element={<CreateDeal />} />
          <Route path="/create_contact" exact element={<CreateContact />} />
          <Route path="/create_tasks" exact element={<CreateTasks />} />

          <Route path="/lead_detail/:id" exact element={<LeadDetail />} />
          <Route path="/deal_detail/:id" exact element={<DealDetail />} />
          <Route path="/contact_detail/:id" exact element={<ContactDetail />} />
          <Route path="/task_detail" exact element={<TaskDetail />} />
          <Route path="/meeting_detail" exact element={<MettingDetail />} />

          <Route path="/notes_ui/:type/:id" exact element={<Notesui />} />
          <Route path="/attachments_ui/:type/:id" exact element={<Attachmentsui />} />

          <Route path="/edit_task/:id" exact element={<CreateTasks />} />
          <Route path="/edit_lead/:lead_id" exact element={<CreateLead />} />
          <Route path="/edit_contact/:contact_id" exact element={<CreateContact />} />
          <Route path="/edit_deal/:deal_id" exact element={<CreateDeal />} />

          <Route path="/track_lead" exact element={<TrackLead />} />
          <Route path="/TeamCLD" exact element={<TeamCLD />} />

          <Route path="/more-options" exact element={<MoreOption />} />
        </Route> */}


        {/* controller component  */}
        {/* <Route element={<RequireAuth allowedRoles={['admin', 'controller']} />}>
          <Route path="/controller_dashboard" exact element={<ControllerDashboard />} />
          <Route path="/on_board" exact element={<OnBoard />} />
          <Route path="/on_board_user_update/:id" exact element={<OnBoard />} />
          <Route path="/departments" exact element={<AddDdr />} />
          <Route path="/designation" exact element={<AddDdr />} />
          <Route path="/roles" exact element={<AddDdr />} />
          <Route path="/Add_DDR" exact element={<AddDdr />} />
          <Route path="/onboarded_employee" exact element={<OnboardEmployee />} />
        </Route> */}

        <Route path="/permission_not_provided" exact element={<PageNotFound />} />
        <Route path="*" exact element={<PageNotFound1 />} />
        <Route path="/link_expired" exact element={<LinkExpire />} />


        {/* dashboard component  */}
        {/* <Route element={<RequireAuth allowedRoles={['admin','controller']} />}>
        <Route path="/rk/krishna_dashboard" exact element={<DeveloperDashboard />} />
        <Route path="/rk/add_industry" exact element={<AddIndustry />} />
        <Route path="/rk/contact_source" exact element={<AddContactSource />} />
        <Route path="/rk/crm_report" exact element={<Reportgeneration />} />
        <Route path="/rk/settings" exact element={<Settings />} />
        <Route path="/rk/userxl_upload" exact element={<UserXLContact />} />
        <Route path="/rk/crm_users" exact element={<UpdateUserPassword />} />
        </Route> */}


        {/* it dashboard component  */}
        {/* <Route element={<RequireAuth allowedRoles={['admin','controller']} />}>
        <Route path="/it/it_dashboard" exact element={<ItDashboard />} />
        <Route path="/it/assets_assign" exact element={<AssetsAssign />} />
        <Route path="/it/wire" exact element={<Wire />} />
        <Route path="/it/purchase_type" exact element={<PurchaseType />} />
        <Route path="/it/processor" exact element={<Processor />} />
        <Route path="/it/brand" exact element={<Brand />} />
        <Route path="/it/types_available" exact element={<Wire />} />
        <Route path="/it/adapters" exact element={<Connector />} />
        <Route path="/it/mouse" exact element={<Mouse />} />
        <Route path="/it/keyboard" exact element={<Keyboard />} />
        <Route path="/it/device_stage" exact element={<DeviceStage />} />
        <Route path="/it/disk" exact element={<Disk />} />
        <Route path="/it/laptop" exact element={<Laptop />} />
        <Route path="/it/desktop" exact element={<Desktop />} />
        <Route path="/it/cpu" exact element={<Cpu />} />
        <Route path="/it/mobile" exact element={<Mobile />} />
        </Route> */}
        
        {/* fms dashboard component  */}
        <Route element={<RequireAuth allowedRoles={['admin','controller','fms_crm_head','fms_data_view', 'hod', 'manager', 'db_head', 'db_user', 'db_sub_user', 'bd_user','fms_user','fms_hr']} />}>

        <Route path="/fms/fms_dashboard" exact element={<FmsDashboardMain />} />
        <Route path="/fms/ticket" exact element={<Tickets />} />
        <Route path="/fms/check_list_option" exact element={<ScanCheckList />} />
        <Route path="/fms/ticket/:type" exact element={<TicketsCE />} />
        <Route path="/fms/epi_form" exact element={<EmployeeInformationForm />} />
        <Route path="/fms/epi_form/duplicate" exact element={<EmployeeInformationForm />} />
        <Route path="/fms/epi_form/edit/:id" exact element={<EmployeeInformationForm />} />
        <Route path="/fms/statement_form" exact element={<StatementForm />} />
        <Route path="/fms/statement_form/create/:id" exact element={<StatementForm />} />
        <Route path="/fms/statement_form/update/:id" exact element={<StatementForm />} />
        <Route path="/fms/form_f_form" exact element={<FormF />} />
        <Route path="/fms/form_f_form/create/:id" exact element={<FormF />} />
        <Route path="/fms/form_f_form/update/:id" exact element={<FormF />} />
        <Route path="/fms/fms_clients" exact element={<FmsClients />} />
        <Route path="/fms/fms_roles" exact element={<FmsRoles />} />
        <Route path="/fms/fms_onboarded_employee_dispensary" exact element={<FmsDispensaryOnboardEmployee />} />
        <Route path="/fms/fms_clients/scan_point" exact element={<FmsClientsPoints />} />
        <Route path="/fms/fms_clients/salary_statement" exact element={<FmsClientsSalaryStatement />} />
        <Route path="/fms/fms_clients/salary_statement/:type" exact element={<FmsClientsSalaryStatementCE />} />
        <Route path="/fms/fms_clients/client_employee" exact element={<FmsEmployeeBasedClient />} />
        <Route path="/fms/fms_clients/download_attendance" exact element={<DownloadClientAttendance />} />
        <Route path="/fms/exit_clients" exact element={<FmsClients />} />
        <Route path="/fms/fms_employee" exact element={<FmsEmployee />} />
        <Route path="/fms/fms_employee_overall" exact element={<FmsEmployeeOverallList />} />
        <Route path="/fms/fms_employee_assign" exact element={<FmsEmployeeAssignList />} />
        <Route path="/fms/fms_inhouse_employee" exact element={<FmsInHouseEmployee />} />
        <Route path="/fms/fms_inhouse_employee/:type" exact element={<FmsInHouseEmployeeCE />} />
        <Route path="/fms/fms_inhouse_employee/:type/:id" exact element={<FmsInHouseEmployeeCE />} />
        <Route path="/fms/fms_employee/:id" exact element={<FmsEmployeeDetail />} />
        <Route path="/fms/fms_onboarded_employee_designation" exact element={<FmsDesignationOnboardEmployee />} />
        <Route path="/fms/fms_employee_exit" exact element={<FmsExitEmployeelist />} />
        <Route path="/fms/long_leave" exact element={<FmsLongLeaveList />} />
        <Route path="/fms/long_leave/:type" exact element={<FmsLongLeaveCreate />} />
        <Route path="/fms/exit_employee" exact element={<FmsExitFormCreate />} />
        <Route path="/fms/exit_employee_based_on_clients" exact element={<ExitEmployeeList />} />
        <Route path="/fms/exit_employee_step" exact element={<FmsExitFormCreate1 />} />
        <Route path="/fms/client_scan_report" exact element={<FmsClientScan />} />
        <Route path="/fms/missed_client_scan_report" exact element={<MissedClientScan />} />
        <Route path="/fms/assigned_clients" exact element={<AssignClients />} />
        <Route path="/fms/assigned_clients_ce" exact element={<AssignClientsCE />} />
        <Route path="/fms/clients_handling" exact element={<HandlingClient />} />
        <Route path="/fms/employee_based_on_clients" exact element={<EmployeeBasedOnClient />} />
        <Route path="/fms/employee_based_on_clients/add_attendance" exact element={<AddAttendanceForSelectedEmployee />} />
        <Route path="/fms/employee_attendance_based_on_clients" exact element={<DownloadAttendence />} />
        <Route path="/fms/attendance_option" exact element={<AttendanceOptionsRoot />} />
        <Route path="/fms/attendance_option_detail" exact element={<AttendanceOptionsCE />} />
        <Route path="/fms/attendance_option_base_list" exact element={<AttendanceOptionBaseList />} />
        <Route path="/fms/upload_excel" exact element={<UploadExcel />} />
        <Route path="/fms/hr_policy" exact element={<FmsHrPolicy />} />
        <Route path="/fms/client_invoice" exact element={<ClientInvoice />} />
        <Route path="/fms/client_invoice/:type" exact element={<ClientInvoiceCE />} />
        <Route path="/fms/job_card" exact element={<JobCardList />} />
        <Route path="/fms/job_card/:type" exact element={<JobCardCE />} />
        <Route path="/fms/work_assigned" exact element={<WorkAssignedList />} />
        <Route path="/fms/work_assigned/detail" exact element={<WorkAssignedListDetail />} />
        <Route path="/fms/work_assigned/:type" exact element={<WorkAssignedCE />} />
       </Route>


      </Routes>



    </div>
  )

}

function App() {




  // const dispatch = useDispatch()


  // useEffect(() => {

  //   const messaging = firebase.messaging();
  //   messaging.usePublicVapidKey("BFB4P-S9nzXYW52rsqY3i1Z4Zw4J4Fc-iY7eNYhtEVn7J1h7RFaTuIO7AfUzA2okWiHxpPYyXQWOaeAM7YewFHg")
  //   messaging
  //     .requestPermission()
  //     .then(() => {
  //       return messaging.getToken();
  //     })
  //     .then((token) => {
  //       dispatch(device_token(token))
  //       // console.log('token is ...', token)
  //     }).catch((err) => {
  //       // console.log('err here', err)
  //     })
  // }, [])

  return (
    <Router>
      {/* <ItDashboard /> */}
      <Root />
      {/* <Main /> */}
      {/* <Base /> */}
    </Router>
  );
}

export default App;